<template>
  <img
    v-bind:src="require(`@/assets/images/icons/ic_${name}.svg`)"
    :width="width || '15%'"
    :class="m0 ? '' : 'mr-1'"
    @click="handleClick"
  />
</template>
<script>
export default {
  name: 'CustomIcon',
  props: ['name', 'width', 'm0'],
  methods: {
    handleClick() {
      this.$emit('click')
    }
  }
}
</script>